<template>
  <div>
    <a-modal
      title="预警通知栏（最近七天）"
      :visible="visible"
      :width="720"
      :maskClosable="false"
      destroyOnClose
      @cancel="handleCancel"
    >

    <a-list item-layout="vertical" size="large" :pagination="pagination" :data-source="list">
      <a-list-item slot="renderItem" key="item.id" slot-scope="item, index">
        <div class="m-card-content" v-bind:key="index">
          <span class="m-name">{{item.projectName}} </span>{{item.name}}于{{$moment(item.endTime).format('YYYY-MM-DD')}}预警
        </div>
      </a-list-item>
    </a-list>
    <!--
    <template v-for="(item, index) in list" style="height:300px;">
      <div class="m-card-content" v-bind:key="index">
        <span class="m-name">{{item.projectName}} </span>{{item.name}}于{{$moment(item.endTime).format('YYYY-MM-DD')}}预警
      </div>
    </template>-->
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
import { moreEarlyWarning } from "@/httpsAPI/workplace/dashboard";

export default {
  data(){
    return{
      visible:false,
      list:[],
      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 10,
      },
    }
  },
  created(){
    this.moreEarlyWarningList()
  },
  methods:{
    onChange(pageNumber) {
      console.log('Page: ', pageNumber);
    },
    moreEarlyWarningList(){
      moreEarlyWarning().then(res=>{
        console.log(res.data)
        this.list = res.data
      })
    },
    handleCancel(e) {
      this.visible = false;
    },
    init(){
      this.visible = true;
    }
  }
}
</script>

<style lang="less" scoped>
 .m-card-content {
    font-size: 12px;
    line-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .m-name {
      color: #d9001b;
    }
  }
</style>>
