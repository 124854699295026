import request from "@/utils/request";

// 概览仪表盘-数据概览
export function statistics(data) {
  return request({
    url: "/dashboard/home/statistics",
    method: "post",
    data,
  });
}

// 概览仪表盘-预警通知栏
export function earlyWarning(data) {
  return request({
    url: "/dashboard/home/earlywarning",
    method: "post",
    data,
  });
}

// 概览仪表盘-实时状态栏
export function projectLog(data) {
  return request({
    url: "/dashboard/home/projectlog",
    method: "post",
    data,
  });
}

// 概览仪表盘-预警通知栏(最近七天)
export function moreEarlyWarning(data) {
  return request({
    url: "/dashboard/home/earlyWarningList",
    method: "post",
    data,
  });
}

// 概览仪表盘-实时状态栏(最近七天)
export function moreProjectLog(data) {
  return request({
    url: "/dashboard/home/projectLogList",
    method: "post",
    data,
  });
}