<template>
  <div>
    <a-row :gutter="12" style="margin-top: 30px;">
      <!-- 仪表盘左侧 -->
      <a-col :md="16">
        <a-row :gutter="12">
          <a-col :md="8">
            <router-link to="/project/index">
              <div :class="'m-module ' + projectColor" style="height:575px">
                <p class="m-title">我的项目</p>
                <div class="m-center">
                  <span>{{ statisticsData.projectStatistics }}</span
                  >个
                </div>
              </div>
            </router-link>
            </a-col>
          <a-col :md="8" :sm="16">
            <!-- <router-link to="/project/index"> -->
              <div :class="'m-module ' + taskColor"> 
                <p class="m-title">我的任务</p>
                <div class="m-center">
                  <span>{{ statisticsData.taskStatistics }}</span
                  >个
                </div>
              </div>
            <!-- </router-link> -->
          </a-col>
          <a-col :md="8">
            <router-link to="/personalDevelopment/learningRecords">
              <div class="m-module normal">
                <p class="m-title">我的课程</p>
                <div class="m-center">
                  <span>{{ statisticsData.courseStatistics }}</span
                  >个
                </div>
              </div>
            </router-link>
          </a-col>
          <a-col :md="8">
            <router-link to="/requirement/list">
              <div :class="'m-module ' + requirementColor">
                <p class="m-title">我的需求</p>
                <div class="m-center">
                  <span>{{ statisticsData.demandStatistics }}</span
                  >个
                </div>
              </div>
            </router-link>
          </a-col>
          <a-col :md="8">
            <router-link to="/worder/list">
              <div class="m-module normal">
                <p class="m-title">求助单</p>
                <div class="m-center">
                  <span>{{ statisticsData.worderStatistics }}</span
                  >个
                </div>
              </div>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
      <!-- 仪表盘右侧 -->
      <a-col :md="8">
        <a-card title="预警通知栏" class="m-card" style="width: 100%;">
          <a slot="extra" @click="moreWarning">更多</a>
          <template v-for="(item, index) in earlyWarningData" style="height:300px;">
            <div class="m-card-content" v-bind:key="index" v-if="index<10">
              <span class="m-name">{{item.projectName}} </span>{{item.name}}于{{$moment(item.endTime).format('YYYY-MM-DD')}}预警
            </div>
          </template>
        </a-card>
        <a-card title="实时状态栏" class="m-card" style="width: 100%;">
          <a slot="extra" @click="moreProject">更多</a>
          <template v-for="(item, index) in projectLogData">
            <div class="m-card-content" v-bind:key="index" v-if="index<10">
              <span class="m-name">{{item.projectName}}  </span>{{item.loginName}}于{{$moment(item.createTime).format('YYYY-MM-DD  HH:mm')}} {{item.dynamicContent}}
            </div>
          </template>
        </a-card>
      </a-col>
    </a-row>
    <!-- 更多预警弹窗 -->
    <more-warn ref="moreMsg" v-if="earlyWarningVisible"></more-warn>
    <!-- 更多实时状态弹窗 -->
    <project-msg ref="projectMsg" v-if="projectVisible"></project-msg>
  </div>
</template>

<script>
import { statistics, earlyWarning, projectLog } from "@/httpsAPI/workplace/dashboard";
import moreWarn from "./components/moreWarnMsg";
import projectMsg from "./components/projectMsg";

export default {
  name: "dashboard",
  data() {
    return {
      statisticsData: [],
      earlyWarningData: [],
      projectLogData: [],
      taskColor: '',
      projectColor: '',
      requirementColor: '',
      earlyWarningVisible:false,
      projectVisible:false
    };
  },
  components: {
    moreWarn,
    projectMsg
  },
  created() {
    this.getStatistics();
    this.getEarlyWarning();
    this.getProjectLog();
  },
  methods: {
    // 七天预警通知栏列表
    moreWarning(){
      this.earlyWarningVisible = true;
      this.$nextTick(() => {
        this.$refs.moreMsg.init();
      });
    },
    // 七天实时状态列表
    moreProject(){
      this.projectVisible = true;
      this.$nextTick(() => {
        this.$refs.projectMsg.init();
      });
    },
    getStatistics() {
      statistics()
        .then((res) => {
          console.log(res);
          this.statisticsData = res.data;
          if(this.statisticsData.taskColor=='red') {
            this.taskColor = 'delay'
          }else if(this.statisticsData.taskColor=='orange') {
            this.taskColor = 'warning'
          } else {
            this.taskColor = 'normal'
          }

          if(this.statisticsData.projectColor=='red') {
            this.projectColor = 'delay'
          }else if(this.statisticsData.projectColor=='orange') {
            this.projectColor = 'warning'
          } else {
            this.projectColor = 'normal'
          }

          if(this.statisticsData.demandColor=='red') {
            this.requirementColor = 'delay'
          }else if(this.statisticsData.demandColor=='orange') {
            this.requirementColor = 'warning'
          } else {
            this.requirementColor = 'normal'
          }
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getEarlyWarning() {
      earlyWarning()
        .then((res) => {
          console.log(res)
          this.earlyWarningData = res.data;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getProjectLog() {
      projectLog({})
        .then((res) => {
          console.log(res)
          this.projectLogData = res.data;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.m-module {
  color: #fff;
  width: 100%;
  height: 280px;
  position: relative;
  margin-top: 15px;
  cursor: pointer;
  &.normal {
    background: #4ead5b;
  }
  &.delay {
    background: #d9001b;
  }
  &.warning {
    background: #e5e510;
  }
  .m-title {
    font-size: 18px;
    padding: 20px 0 0 15px;
  }
  .m-center {
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > span {
      font-size: 50px;
    }
  }
}
.m-card {
  margin-top: 15px;
  .m-card-content {
    font-size: 12px;
    line-height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .m-name {
      color: #d9001b;
    }
  }
}
/deep/.ant-card-body{
  overflow-y: auto;
  height: 223px;
  .m-card-content{
    line-height: 36px;
  }
}
</style>
